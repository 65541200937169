import React from 'react';
import styled from 'styled-components';
import {Breakpoints} from '../../constants';
import BannerFirst from '../../../static/images/intro/ATTCINEMAS2.jpg';
import {SectionHeader} from '../Landing/SectionHeader';
import Group from '../../../static/images/intro/ATT-團體電影優惠券(0514)-02.jpg';

const BusinessPage = (props) => {
  // if (isLoading) {
  //   return <Spinner />;
  // }
  return (
    <Wrapper>
      <HeroBannerSection onClick={async (e) => {}} image={BannerFirst} />
      <div className="content">
        <SectionHeader
          title="業務專區"
          caption="buisness"
          style={{marginBottom: 30}}
        />

        <img
          className="ticket-project-image"
          width={400}
          height={180}
          src={Group}
          alt="ticket project"
        />

        <div className="title">ATT吸引力影城團體電影優待券【1本50張】</div>
        <div className="introduction">
          {`
1. 團體電影優惠券金額為230元，本券可加價使用特殊版本／影廳之影片，加價金額請洽影城售票窗口。
2. 團券一本為50張，不可單張販售，優待期限為一年，不限場次時間。
3. 片長如有超過150分鐘之影片(含150分鐘)加$10元，爾後每30分鐘片長級距，另加$10元，依此類推。
4. 請先下載訂購單，完成訂票手續後，需3~5個工作天製作票券。(不含假日)
5. 本券因毀損或變形而其票根條碼仍可經由售票機台辨識者，得至售票窗口申請補發，補發費用每張為$30元。
6. 付款方式：
     A、現場刷卡領票
     B、現場付現領票
     C、匯款後郵寄票券
7. 取票地點：大直ATT吸引力影城 (台北市中山區敬業三路 123 號 8 樓)
8. 大量購票另有優惠(1,000張以上)，歡迎來電洽詢。
9. 業務聯繫電話：(02)7700-7040 黃小姐 / 賴先生。
`}
        </div>

        <div className="title">ATT吸引力影城 包廳 / 團體劃位</div>
        <div className="introduction">
          {`
            團體劃位: 25人以上觀賞同場次電影(該廳部分席次)，即可享有團體價格並優先劃位。
包廳活動: 依照影廳座位數全數包下整場影廳(價格=影廳座位數*團體票價)，最少22人即可包廳，開場前享有10分鐘廳內活動、迎賓桌、歡迎告示、麥克風等...頂級影廳設備也可撥放自製影片，滿足各式學校班級、公司團體員工電影欣賞、家庭日、招待客戶、行銷活動需求。

1. 請於7-10個工作天前來電預定。
2. 片長超過150分鐘或特殊節日需加價，確認後於付款前另行告知。
3. 包廳與團劃活動依現場排片為主 / 席次安排以預訂先後順序為主。
4. 團劃包廳活動為專屬優惠活動，出票後恕不退換。
5. 新片上映首週、假日與假日前一晚，需搭配可樂爆米花組合餐飲。
            `}
        </div>

        <div className="title">ATT吸引力影城影廳租借</div>

        <div className="introduction">
          {`
          大直ATT吸引力影城共推出14個影廳，近千個座位數，並主打全影廳無死角的黃金座位，劇院式階梯形的優質場地，同時提供數位放映、藍光、筆電播放系統皆可，適合各大行銷通路活動使用。

【ACES 王牌廳】台灣首座搭載Dolby System 136銀幕聲道喇叭高規格影廳。
【HONEY Q 情人廳】全台首創情人主題式影廳，提供給戀人們專屬隱密觀影空間體驗。
【SUSEE 舒適廳】全台最舒服影廳首選，提升排距給你如家一般放鬆愜意之感受。
【一般廳】影城囊括大、中、小廳，可滿足多樣式行銷活動及多元規劃需求。
            `}
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .content {
    & > img.ticket-project-image {
      width: 400px;
      height: 180px;
      object-fit: contain;
      margin-bottom: 20px;
    }
    max-width: var(--contentMaxWith);
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    & > .title {
      font-weight: bold;
    }
    & > .introduction {
      font-size: 13px;
      color: #000000;
      white-space: pre-line;
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: ${Breakpoints.md}px) {
    & > .content {
      padding: 50px 20px;

      & > img.ticket-project-image {
        width: 100%;
        height: 180px;
      }
    }
  }
`;

const HeroBannerSection = styled.section`
  padding: 80px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: ${({image}) => `url(${image})`};
  background-position: center;
  background-size: cover;
  background-color: orange;
  & > h2 {
    font-size: 32px;
    color: white;
  }
  & > p {
    font-size: 18px;
    color: #ccc;
  }

  @media screen and (max-width: ${Breakpoints.sm}px) {
    min-height: 180px;
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    min-height: 300px;
  }
`;

export default BusinessPage;
